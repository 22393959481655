<template>
  <div class="cursorBox bg000">
    <div v-if="apiShow">
      <PcHeader :parseVal="parseVal" :languageData="languageData" :isSelect="true" />
      <div class="main">
        <div class="aboutUsBox" id="aboutUs" ref="aboutUs">
          <div class="aboutUsTit bounceInBox">{{ filterFn('aboutUsText', 'title') }}</div>
            <div class="aboutUsDes bounceInBox" v-html="filterFn('aboutUsText', 'text')"></div>
          <div class="usMain">
            <div v-for="(item, index) in aboutData" :key="index" class="about-item animate__animated rubberBandBox">
              <number 
                  :ref="'aboutNum' + index"
                  :from="0" 
                  :to="item.num"
                  style="color: #ab72f8;"></number>
                <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="aboutBox" id="about">
          <div class="tit bounceInBox">{{ filterFn('partnersText', 'title') }} </div>
          <div class="des bounceInBox" v-html="filterFn('partnersText', 'text')"></div>
          <div class="light animate__animated fadeInRight">
            <img :src="officialCertificationImg('partnersImg', '框1')" />
            <img :src="officialCertificationImg('partnersImg', '框2')" />
            <img :src="officialCertificationImg('partnersImg', '框3')" />
            <img :src="officialCertificationImg('partnersImg', '框4')" />
            <img :src="officialCertificationImg('partnersImg', '框5')" />
            <img :src="officialCertificationImg('partnersImg', '框6')" />
            <img :src="officialCertificationImg('partnersImg', '框7')" />
            <img :src="officialCertificationImg('partnersImg', '框8')" />
          </div>
        </div>
        <Pcfooter :parseVal="parseVal" />
      </div>
    </div>
  </div>
</template>

<script>
import PcHeader from "@/components/pc/PcHeader2.vue";
import Pcfooter from "@/components/pc/Pcfooter.vue";
import { getList } from '@/api'
export default {
  name: '',
  props: { },
  components: {
    PcHeader,
    Pcfooter
  },
  data() {
    return {
      lang: '',
      apiShow: false,
      languageData: [],
      parseVal: [],
      // 关于我们数据
      aboutData: [
        {
          number: 1,
          label: this.$i18n.t("mainData_p")
        },
        {
          number: 5,
          label: this.$i18n.t("mainCenter_p")
        },
        {
          number: 10,
          label: this.$i18n.t("mainLanguage_p")
        },
        {
          number: 24,
          label: this.$i18n.t("mainSafety_p")
        }
      ],
     }
  },
  methods: {
    filterFn(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.key1 === this.lang)
      return item ? item[key] : ''
    },
    officialCertificationImg(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      console.log(name, 'ss8ss8s8')
      const item = name.find(ele => ele.name === key)
      return item ? ('https://admin.gfg888.com/td/api/photo/' + item['computerSrc']) : ''
    },
    loadApi() {
      this.apiShow = false
      getList({ 
        typeString: 'carousel,config,language,guideText,filmText,film,popularGameText,popularGame,propagateText,gamePresentationText,gamePresentation,aboutUsText,aboutUs,officialCertificationText,officialCertification,officialCertificationImg,partnersText,partnersImg,contactUsText,contactUs',
        pageIndex:1, pageSize: 999999, type: 'xxx'
      }).then(res => {
        if (res.data.code) {
          const val = res.data.data.items
          const parseVal = val.map(ele => {
            return { ...ele, ...JSON.parse(ele.jsonString)}
          })
          this.languageData = parseVal.filter(ele => ele.type === 'language')
          
          this.languageData.forEach((ele, index) => {
            if (!localStorage.getItem('LANG_NAME') && index === 0) {
              localStorage.setItem("LANG_NAME", ele.key1);
              this.lang = ele.key1
            }
          })
          if (!(this.languageData.map(ele => ele.key1).includes(localStorage.getItem('LANG_NAME')))) {
            localStorage.setItem("LANG_NAME", this.languageData[0].key1);
            this.lang = this.languageData[0].key1
          }

          this.parseVal = parseVal

          // 关于我们
          this.aboutData = []
          const tableDataVal = ['tableData1', 'tableData2', 'tableData3', 'tableData4']
          const aboutUs = parseVal.filter(ele => ele.type === 'aboutUs')
          aboutUs.forEach(ele => {
            if (tableDataVal.includes(ele.key2) && ele.name === this.lang) {
              this.aboutData[parseInt(ele.key2.match(/\d+/)[0] - 1)] = ele
            }
          })

        }
      }).finally(() => {
        this.apiShow = true
        this.loadObserve()
      })
    },
    loadObserve() {
      this.$nextTick(() => {
        // 文字动画
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__bounceIn')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__bounceIn')
            }
          })
        })
        const cards = document.querySelectorAll('.bounceInBox')
        cards.forEach((card, index) => {
          card.dataset.index = index
          observer.observe(card, index)
        })

        // 向右到左的动画
        const observer2 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__fadeInRight')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__fadeInRight')
            }
          })
        })
        const cards2 = document.querySelectorAll('.fadeInRight')
        cards2.forEach((card, index) => {
          card.dataset.index = index
          observer2.observe(card, index)
        })

        // 联系的动画
        const observer3 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.querySelector('.zoomInLeft').classList.add('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.add('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.add('animate__zoomInDown')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.querySelector('.zoomInLeft').classList.remove('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.remove('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.remove('animate__zoomInDown')
            }
          })
        })
        const cards3 = document.querySelectorAll('.info-circle')
        cards3.forEach((card, index) => {
          card.dataset.index = index
          observer3.observe(card, index)
        })

        // 热门游戏动画
        const observer4 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.add('animate__rotateInDownLeft')
              } else {
                entry.target.classList.add('animate__rotateInDownRight')
              }
            } else {
              // 当元素离开视窗时移除类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.remove('animate__rotateInDownLeft')
              } else {
                entry.target.classList.remove('animate__rotateInDownRight')
              } 
            }
          })
        })
        const cards4 = document.querySelectorAll('.gameFlipOutYBox')
        cards4.forEach((card, index) => {
          card.dataset.index = index
          observer4.observe(card, index)
        })

        // 动画
        const observer5 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__rubberBand')
              for (let i = 0; i < 4; i++) {
                const ele = this.$refs['aboutNum' + i ]
                ele && ele[0].restart()	
              }
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__rubberBand')
            }
          })
        })
        const cards5 = document.querySelectorAll('.rubberBandBox')
        cards5.forEach((card, index) => {
          card.dataset.index = index
          observer5.observe(card, index)
        })
      })
    },
  },
  created() {
    this.lang = localStorage.getItem("LANG_NAME")
    this.loadApi()
  },
  watch: {

  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.main{
  // margin-top: 100 ./ @design-width * 95vw;
}
@design-width: 1920;
.px2vw(@name, @px) {
  @{name}: (@px / @design-width) * 100vw;
}

.aboutUsBox {
  .px2vw(height, 944);
  width: 100%;
  background: url(~@/assets/img/pc/aboutUs_bg.jpg);
  background-size: cover;
  color: #000;
  padding: 200 ./ @design-width * 100vw 0;
  .aboutUsTit {
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0vw auto 20 ./ @design-width * 100vw;
    text-align: center;
    color: #fff;
    font-family: "fontText";
  }
  .aboutUsDes {
    padding: 0 22.2vw;
    padding-bottom: 80 ./ @design-width * 100vw;
    padding-top: 0;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    color: #e7e7e7;
    font-family: MicrosoftYaHei;
  }
  .usMain {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    > .about-item {
    .px2vw(width, 269);
    .px2vw(height, 278);
      margin: 1vw 0px;
      background: url(~@/assets/img/pc/aboutUs_frame.png) center no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 0.8vw 1.5vw;
      // > h2 {
      //   font-size: 80 ./ @design-width * 100vw;
      //   font-family: usFont;
      //   color: #ab72f8;
      // }
      p {
        width: 82%;
        font-size: 24 ./ @design-width * 100vw;
        color: #fff;
        text-align: center;
        line-height: 30 ./ @design-width * 100vw;
      }
      span {
        font-size: 66 ./ @design-width * 100vw;
        font-family: usFont;
        font-weight: bold;
        color: #9f9d9d;
      }
    }
  }
}
.aboutBox {
  font-family: 'fontTitle';
  width: 100%;
  // background: #fff;
  color: #fff;
  padding: 8.3vw 0;
  background: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  
  .tit {
    color: #000;
    font-size: 45 ./ @design-width * 100vw;
    font-family: 'fontText';
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 20 ./ @design-width * 100vw;
    text-align: center;
  }
  .des {
    padding: 0.5vw 22.2vw;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 3.1vw;
    color: #aeafb3;
    font-family: MicrosoftYaHei;
  }
  .light {
    display: flex;
    width: 80%;
    height: auto;
    justify-content: center;
    flex-flow: wrap;
    margin: 0 auto;
    img {
    .px2vw(margin, 5);
    .px2vw(width, 340);
    .px2vw(height, 120);
    }
  }
}
.cursorBox {
  cursor:url('../../assets/img/pc/cursor.png'),auto;
}
.bg000 {
  background: #000;
  height: 100vh;
}
</style>